import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
// import reportWebVitals from './reportWebVitals'
// import firebase from 'firebase/app'

// firebase.initializeApp({
//   apiKey: "AIzaSyAbr6VLtQpRktIrAm9biEQpOU2hjGAZadQ",
//   authDomain: "circuitry-c4337.firebaseapp.com",
//   projectId: "circuitry-c4337",
//   storageBucket: "circuitry-c4337.appspot.com",
//   messagingSenderId: "238701717359",
//   appId: "1:238701717359:web:4610da3738e79c477dea9a",
//   measurementId: "G-3ZTK3TZ3YL"
// })

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
